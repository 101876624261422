var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { FormLayout } from "@/pages/KYBPage/components/FormLayout";
import { FormRow } from "@/pages/KYBPage/components/FormRow";
import { parseArray, stringifyArray } from "@/pages/KYBPage/utils";
import { ROUTES } from "@/routes";
import { Box, FormControlInput, Switch, Typography } from "@/shared/components";
import { PhoneInput } from "@/shared/components/FormControlInput/PhoneInput";
import { FormControlMultiSelect } from "@/shared/components/FormControlMultiSelect";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { countries } from "@/shared/constants/countries";
import { ACTIVITIES, REGULATED_STATUS, VENDORS_AND_COUNTERPARTIES } from "@/shared/constants/dict";
import primaryBusinessDict from "@/shared/constants/primaryBusiness.json";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { getCountryStates } from "@/shared/utils/country";
import { RequiredFieldMessage, WrongFormatMessage } from "@/shared/utils/error";
import { onCheckbox } from "@/shared/utils/input";
import { EMAIL_REGEXP } from "@/shared/utils/is-email";
export const Form3 = observer(() => {
    var _a;
    const navigate = useNavigate();
    const companyInfo = useStoreContext(CompanyInfoStore);
    const form = useForm();
    const [addressMatchEnabled, setAddressMatchEnabled] = React.useState(true);
    const submit = form.handleSubmit((formData) => __awaiter(void 0, void 0, void 0, function* () {
        const { meta } = formData;
        const data = Object.assign(Object.assign({}, formData), { meta: Object.assign(Object.assign({}, meta), { physicalCountryCode: addressMatchEnabled
                    ? meta === null || meta === void 0 ? void 0 : meta.registeredCountryCode
                    : meta === null || meta === void 0 ? void 0 : meta.physicalCountryCode, physicalState: addressMatchEnabled
                    ? meta === null || meta === void 0 ? void 0 : meta.registeredState
                    : meta === null || meta === void 0 ? void 0 : meta.physicalState, physicalStreetAddress: addressMatchEnabled
                    ? meta === null || meta === void 0 ? void 0 : meta.registeredStreetAddress
                    : meta === null || meta === void 0 ? void 0 : meta.physicalStreetAddress, physicalCity: addressMatchEnabled
                    ? meta === null || meta === void 0 ? void 0 : meta.registeredCity
                    : meta === null || meta === void 0 ? void 0 : meta.physicalCity, physicalZipCode: addressMatchEnabled
                    ? meta === null || meta === void 0 ? void 0 : meta.registeredZipCode
                    : meta === null || meta === void 0 ? void 0 : meta.physicalZipCode }) });
        companyInfo.update(data).then((success) => {
            if (success)
                navigate(generatePath(ROUTES.kybFormId, { id: 4 }));
        });
    }));
    React.useEffect(() => {
        var _a;
        form.reset(companyInfo.companyInfo);
        const meta = (_a = companyInfo.companyInfo) === null || _a === void 0 ? void 0 : _a.meta;
        const cityMatch = (meta === null || meta === void 0 ? void 0 : meta.registeredCity) === (meta === null || meta === void 0 ? void 0 : meta.physicalCity);
        const stateMatch = (meta === null || meta === void 0 ? void 0 : meta.registeredState) === (meta === null || meta === void 0 ? void 0 : meta.physicalState);
        const countryCodeMatch = (meta === null || meta === void 0 ? void 0 : meta.registeredCountryCode) === (meta === null || meta === void 0 ? void 0 : meta.physicalCountryCode);
        const streetAddressMatch = (meta === null || meta === void 0 ? void 0 : meta.registeredStreetAddress) === (meta === null || meta === void 0 ? void 0 : meta.physicalStreetAddress);
        const zipCodeMatch = (meta === null || meta === void 0 ? void 0 : meta.registeredZipCode) === (meta === null || meta === void 0 ? void 0 : meta.physicalZipCode);
        const emptyForm = !(meta === null || meta === void 0 ? void 0 : meta.physicalCity)
            && !(meta === null || meta === void 0 ? void 0 : meta.physicalState)
            && !(meta === null || meta === void 0 ? void 0 : meta.physicalCountryCode)
            && !(meta === null || meta === void 0 ? void 0 : meta.physicalStreetAddress)
            && !(meta === null || meta === void 0 ? void 0 : meta.physicalZipCode);
        const addressMatch = cityMatch
            && stateMatch
            && countryCodeMatch
            && streetAddressMatch
            && zipCodeMatch;
        setAddressMatchEnabled(emptyForm || addressMatch);
    }, [form, companyInfo.companyInfo]);
    const registeredCountryCode = useWatch({
        control: form.control,
        name: "meta.registeredCountryCode",
    });
    const registeredStates = React.useMemo(() => (registeredCountryCode ? getCountryStates(registeredCountryCode) : []), [registeredCountryCode]);
    const physicalCountryCode = useWatch({
        control: form.control,
        name: "meta.physicalCountryCode",
    });
    const physicalStates = React.useMemo(() => (physicalCountryCode ? getCountryStates(physicalCountryCode) : []), [physicalCountryCode]);
    return (React.createElement(FormLayout, { onSubmit: submit, onBack: () => navigate(generatePath(ROUTES.kybFormId, { id: 2 })), index: 3, error: (_a = companyInfo.errors[0]) === null || _a === void 0 ? void 0 : _a.description, title: "Company details", desc: "Fill in the fields below with your company information. The company's data must match with the uploaded documents. Please, use Latin letters only.", loading: !companyInfo.ready || companyInfo.loading },
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "registrationNumber", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Registered Number", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "meta.registeredCountryCode", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: countries.map((item) => ({
                        key: item.codeAlpha2,
                        label: `${item.flag} ${item.name}`,
                    })), label: "Registered Country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.registeredState", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: registeredStates, label: "Registered State/Province", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
            React.createElement(Controller, { control: form.control, name: "meta.registeredCity", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Registered City", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.registeredStreetAddress", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Registered Street Address", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "meta.registeredZipCode", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Registered Zip Code/Postal Code", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.companyPhoneNumber", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(PhoneInput, { onChange: field.onChange, value: field.value, error: error === null || error === void 0 ? void 0 : error.message, disabled: companyInfo.loading || field.disabled })) }),
            React.createElement(Controller, { control: form.control, name: "meta.companyWebsite", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { InputProps: {
                        endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                    }, disabled: companyInfo.loading || field.disabled, label: "Website address", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.companyEmail", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                    pattern: {
                        value: EMAIL_REGEXP,
                        message: WrongFormatMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Email", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "meta.establishedOn", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(MaskedInput, Object.assign({}, field, { mask: [
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                    ], render: (innerRef, props) => (React.createElement(FormControlInput, Object.assign({}, props, { ref: innerRef, value: props.defaultValue, disabled: companyInfo.loading || field.disabled, label: "Established on", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", placeholder: "YYYY-MM-DD" }))) }))) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.stateOfIncorporation", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "State of Incorporation", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "meta.businessJurisdictions", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlMultiSelect, { name: field.name, onChange: (_, v) => {
                        field.onChange(stringifyArray(v === null || v === void 0 ? void 0 : v.map((item) => item.key)));
                    }, multiple: true, value: parseArray(field.value), options: countries.map((item) => ({
                        key: item.codeAlpha2,
                        label: `${item.flag} ${item.name}`,
                    })), label: "Business jurisdictions", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.fundsSendReceiveJurisdictions", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlMultiSelect, { name: field.name, onChange: (_, v) => {
                        field.onChange(stringifyArray(v === null || v === void 0 ? void 0 : v.map((item) => item.key)));
                    }, multiple: true, value: parseArray(field.value), options: countries.map((item) => ({
                        key: item.codeAlpha2,
                        label: `${item.flag} ${item.name}`,
                    })), label: "Funds send receive jurisdictions", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
            React.createElement(Controller, { control: form.control, name: "meta.engageInActivities", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlMultiSelect, { name: field.name, onChange: (_, v) => {
                        field.onChange(stringifyArray(v === null || v === void 0 ? void 0 : v.map((item) => item.key)));
                    }, multiple: true, value: parseArray(field.value), options: ACTIVITIES.map((item) => ({
                        key: item.key,
                        label: item.label,
                    })), label: "Engage in activities", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
            React.createElement(Controller, { control: form.control, name: "meta.vendorsAndCounterparties", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlMultiSelect, { name: field.name, onChange: (_, v) => {
                        field.onChange(stringifyArray(v === null || v === void 0 ? void 0 : v.map((item) => item.key)));
                    }, multiple: true, value: parseArray(field.value), options: VENDORS_AND_COUNTERPARTIES.map((item) => ({
                        key: item.key,
                        label: item.label,
                    })), label: "Vendors and counterparties", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.regulatedStatus", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: REGULATED_STATUS.map((item) => ({
                        key: item.key,
                        label: item.label,
                    })), label: "Regulated status", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
            React.createElement(Controller, { control: form.control, name: "meta.corporateTaxReferenceNumber", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Tax Reference Number", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.primaryBusiness", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: primaryBusinessDict.map((item) => ({
                        key: item.key,
                        label: item.label,
                    })), label: "Primary business", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
        React.createElement(Box, { height: "1px", bgColor: "black-100", margin: "20px 0" }),
        React.createElement(Typography, { type: "text16", fontWeight: 450, variant: "body1", bgColor: "black-50", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "36px 30px", borderRadius: "16px", color: "black-500" },
            "Physical address matches registered address",
            React.createElement(Switch, { onChange: onCheckbox(setAddressMatchEnabled), checked: addressMatchEnabled })),
        !addressMatchEnabled && (React.createElement(React.Fragment, null,
            React.createElement(FormRow, null,
                React.createElement(Controller, { control: form.control, name: "meta.physicalCountryCode", rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: countries.map((item) => ({
                            key: item.codeAlpha2,
                            label: `${item.flag} ${item.name}`,
                        })), label: "Physical Country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
                React.createElement(Controller, { control: form.control, name: "meta.physicalState", rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: physicalStates, label: "Physical State/Province", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
            React.createElement(FormRow, null,
                React.createElement(Controller, { control: form.control, name: "meta.physicalStreetAddress", rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Physical Street Address", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
                React.createElement(Controller, { control: form.control, name: "meta.physicalCity", rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Physical City", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) })),
            React.createElement(FormRow, null,
                React.createElement(Controller, { control: form.control, name: "meta.physicalZipCode", rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Physical Zip Code/Postal Code", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }))))));
});
